import React from 'react'
import Card from '../../components/Card'

import "./Leaderboard.scss"

function Leaderboard(props) {

    return (
        <Card styleClassName="leaderboard">

            <p>Hatsch woll denkt haha</p>

        </Card>
    )
}

export default Leaderboard
